<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="sourceObject.results.length == 0"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createServiceGroup"
          append
          class="btn btn-add btn_color_green"
        >
          Добавить группу объектов
        </router-link>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="serviceGroups"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";

export default {
  name: "ServesOrganizationObject",
  data() {
    return {
      queries: query.serviceGroups,
      header: [
        {
          text: "Название",
          value: "name",
          renderFunction: value => {
            return value.name
              ? value.name
              : `<div>Группа объектов ${value.id}<div>`;
          },
          sort: true
        },
        {
          text: "Объектов",
          value: "object_count",
          sort: true
        }
      ]
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getPageServiceGroupsList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.$store.dispatch("downloadServiceGroupsByIdRequest", this.header);
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchServiceGroupsList").finally(() => {
      this.isDateLoaded = false;
    });
  },

  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          query: { ...this.urlQuery.query },
          id: this.urlQuery.id
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchServiceGroupsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearServiceGroupsPage");
    next();
  }
};
</script>

<style scoped></style>
