<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createServiceOrganizationTask"
          append
          class="btn btn-add btn_color_green"
          >Добавить заявку</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to-raw="ServiceTask"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsServiceTask from "@/helpers/tableCols/serviceTask";
export default {
  name: "ServesOrganizationServiceTasks",
  data() {
    return {
      loadingBtn: false,
      queries: query.serviceTask
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsServiceTask;
    },
    sourceObject() {
      return this.$store.getters.getOrganizationServiceTasksList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadServiceOrganizationServiceTasksList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, service_organization: this.urlQuery.id },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchOrganizationServiceTasksList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            service_organization: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchOrganizationServiceTasksList")
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearServiceTasksPage").then(() => next());
  // }
};
</script>

<style scoped></style>
